import styles from '@/components/elements/buttons/CustomButton/CustomButton.module.scss'
import Link from 'next/link'

interface CustomButtonProps {
  label: string
  href?: string
  targetBlank?: boolean
  className?: string
}

const CustomButton = ({ label, href, targetBlank = false, className }: CustomButtonProps) => {
  let classString = styles.root
  if (className !== undefined) {
    classString += ' ' + className
  }
  return (
    <div className={`clearfix ${classString}`}>
      {href !== undefined ? (
        <Link href={href} passHref>
          <a className={styles.ribbon} target={targetBlank ? '_blank' : undefined} dangerouslySetInnerHTML={{ __html: label }} />
        </Link>
      ) : (
        <div className={styles.ribbon} dangerouslySetInnerHTML={{ __html: label }} />
      )}
    </div>
  )
}

export default CustomButton
