import { getCloudinaryThemeFileUrl } from '@/integrations/cloudinary/helpers'
import { TitleDecorationProps } from 'components/cms/TitleDecoration/TitleDecoration'

const TitleDecoration = ({ className = '' }: TitleDecorationProps) => {
  return (
    <>
      <div className={`root ${className}`} />
      <style jsx>
        {`
          .root {
            background-image: url(${getCloudinaryThemeFileUrl('images/icons/dots/all.png')});
            height: 25px;
            background-repeat: no-repeat;
            background-size: contain;
          }
        `}
      </style>
    </>
  )
}

export default TitleDecoration
